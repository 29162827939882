.point-history__table-container {
  table {
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
    th,
    td {
      min-width: 13rem;
    }
  }
}
