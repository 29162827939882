/* 연속되어 연결된것 처럼 표기 */
$input-border-size: 1px;
.input-chaining {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;

  .form-control {
    position: relative;
    width: auto;
    flex: 1 1 auto;

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .append *,
  .form-control + .form-control {
    display: flex;
    margin-left: -$input-border-size;
  }
  .form-control + .append *,
  .form-control + .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.validation-message {
  color: #dc3545;
  font-size: 12px;
  font-weight: 600;

  &--success {
    color: #28a745;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5;

  .fa-info-circle {
    font-size: 14px;
  }
}

// 공통 폼
.form-group {
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #636e72;
  margin-bottom: 5px;
  & > label {
    margin-right: 20px;
    text-align: end;
    width: 70px;
    font-weight: 600;
  }
  small {
    padding-top: 10px;
  }
}
.form-group + .form-group {
  margin-top: 10px;
}

// 라디오버튼 폼 공통
.form-group.radio {
  .input-group {
    border: none;

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      input {
        cursor: pointer;
        margin-right: 3px;
      }
    }
  }
}

/* 폼에 속한 input control */
.form-control {
  display: block;
  width: 100%;
  height: auto;
  min-height: 37px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: $input-border-size solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #fff;
    border-color: #66afe9;
  }
  &:focus-visible {
    outline: none;
  }
  &.disabled {
    box-shadow: unset !important;
    background-color: #e9ecef !important;
  }
  &.is-valid {
    border-color: #28a745;
  }
  &.is-invalid {
    border-color: #dc3545;
  }
  &::placeholder {
    color: #b7bac9;
    opacity: 1;
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0px;
}
.table-bordered {
  margin: 0;
  padding: 0;
  th {
    border: 1px solid #dee2e6;
    padding: 14px 8px;
  }
  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
  }
}

.c-checkbox,
.c-radio {
  &:not(:first-child) {
    margin-bottom: 8px;
    small {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .check {
    margin-right: 3px;
  }
}

.c-checkbox,
.c-radio {
  margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
  cursor: pointer;
}
.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}
.c-checkbox span.fa-check,
.c-radio span.fa-check,
.c-radio span.fa-circle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin-right: 5px;
  text-align: center;
}

.c-checkbox:hover span,
.c-radio:hover span {
  border-color: #5d9cec;
}
.form-inline .c-checkbox span.fa-check,
.form-inline .c-radio span.fa-check,
.form-inline .c-radio span.fa-circle {
  margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
  border-radius: 500px;
}

/* override for radio */
.c-radio span.fa-check,
.c-radio span.fa-circle {
  border-radius: 500px;
}

/* the icon */
.c-checkbox span.fa-check:before,
.c-radio span.fa-check:before,
.c-radio span.fa-circle:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  font-size: 12px;
  line-height: 18px;
}

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-checkbox input[type="checkbox"]:checked + span:before,
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="checkbox"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color 0.3s ease-out;
}
.c-checkbox input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-radio input[type="radio"]:checked + span {
  border-color: #5d9cec;
  background-color: #5d9cec;
}
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
  background-color: #fff;
}
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
  color: #5d9cec;
}
.c-checkbox input[type="checkbox"]:disabled + span,
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="checkbox"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
  border-color: #ddd !important;
  background-color: #ddd !important;
}
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
  background-color: #fff !important;
}
.c-checkbox input[type="radio"]:disabled + span:before,
.c-radio input[type="radio"]:disabled + span:before {
  color: #ddd;
}

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-radio.c-radio-nofont span:before {
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color 0.3s ease-out;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
  border-color: #5d9cec;
  background-color: #5d9cec;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span {
  background-color: #fff;
}
.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
  background-color: #5d9cec;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
  border-color: #ddd !important;
  background-color: #ddd !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
  background-color: #fff !important;
}
.c-radio.c-radio-nofont input[type="radio"]:disabled + span:before {
  background-color: #ddd;
}

$input-height: 37px;
$input-line-height: 1.5;
$input-border-radius: 3px;

//셀렉트 박스
.select-box {
  width: 150px;
  height: $input-height;
  border: 1px solid #ced4da;
  border-radius: $input-border-radius;
  padding-left: 5px;
  outline: none;
  font-size: 0.875rem;
  line-height: $input-line-height;
  color: #495057;
  &:focus {
    border-color: #66afe9;
  }
}

/* date-picker 스타일링 */
.react-datepicker-wrapper {
  width: 80% !important;
}

.date-picker__control {
  display: block;
  width: 100%;
  height: auto;
  min-height: 37px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: $input-border-size solid #ced4da;
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #fff;
    border-color: #66afe9;
  }
  &:focus-visible {
    outline: none;
  }
  &.disabled {
    box-shadow: unset !important;
    background-color: #e9ecef !important;
  }
  &.is-valid {
    border-color: #28a745;
  }
  &.is-invalid {
    border-color: #dc3545;
  }
  &::placeholder {
    color: #b7bac9;
    opacity: 1;
  }
}

.date-picker__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 37px;
  background-color: #e9ecef;
  border: $input-border-size solid #ced4da;
  border-left: none;
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
}

.date-picker-container {
  width: 420px;
  display: flex;

  .date-picker-chaining {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: auto;
  }
}

.date-picker-between {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

/* 결과 화면 */
.result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  color: #555;

  &__contents {
    align-items: center;
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      div + div {
        margin-top: 50px;
      }
    }
    &-sub {
      margin-top: 100px;
      text-align: center;

      p + p {
        margin-top: 30px;
      }
    }
    &-direction {
      display: flex;
      margin-top: 100px;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}
