/* 변역현황요약 카드 스타일 */
.trans-progress-card {
  font-size: 16px;
  width: 140px;
  padding: 0.625rem 0.9375rem;
  margin: 0 20px;
  border: 1px solid #e0e0e0;
  border-top: 3px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 5%);
  position: relative;

  &__number {
    padding: 5px;
    text-align: center;
    span {
      color: #24b8e6;
      font-weight: 600;
      font-size: 24px;
      margin-right: 3px;
    }
    em {
      position: absolute;
      font-size: 16px;
      top: 7px;
      right: 7px;
      cursor: pointer;
    }
  }
}
