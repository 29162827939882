.btn-radio-container {
  display: inline-block;

  .btn-radio {
    display: inline-block;
    position: relative;
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;

    input[type="radio"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    input[type="radio"] + label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 9px 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      font-size: 13px;
      color: #555555;
      font-weight: normal;
      border: 1px solid #888888;
      border-right: 0;
    }

    &:first-child > input[type="radio"] + label {
      border-radius: 4px 0 0 4px;
    }

    &:last-child > input[type="radio"] + label {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #888888;
    }

    input[type="radio"]:checked + label {
      color: #ffffff;
      background-color: #555555;
      border-color: #555555;
    }
  }
}
