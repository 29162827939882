.editor__header {
  width: 100%;
  span {
    font-size: 18px;
    font-weight: 600;
  }
}

.editor__main {
  padding: 0 20px;
  width: 100%;
  label {
    display: block;
    text-align: end;
    margin-right: 20px;
    width: 70px;
    font-weight: 600;
    color: #636e72;
  }
  input {
    width: calc(100% - 90px);
  }
}
.editor__footer__btn {
    all: unset;
    align-items: right;
    margin: 7px 0px 0px auto;
    background-color: #fff;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    span {
      margin-left: 5px;
    }
    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
}

.editor__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a:hover {
    text-decoration: none;
  }

  .list-btn {
    all: unset;
    margin: 7px auto;
    background-color: #17a2b8;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    span {
      margin-left: 5px;
    }
    &:hover {
      background-color: #138496;
      border-color: #117a8b;
    }
  }
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: calc(100% - 70px);
}

.ck-editor__editable_inline {
  min-height: 300px;
  width: 100%;
}

.ck.ck-sticky-panel__content {
  top: 80px !important;
}

/*일반게시판 첨부파일 뱃지 스타일*/
.attach-file {
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #636e72;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 90px;

  &__item {
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0 0 3px 3px;

    a:hover {
      text-decoration: underline;
      color: #17a2b8;
    }
    .fa-times {
      cursor: pointer;
      color: #f05050;
      &:hover {
        color: #c82333;
      }
    }
  }
}
