.txt-de { font-size: 17x; } 
.txt-de+.h2 { margin-top: 20px;}

.h2 { font-size: 18px; font-weight: 600; margin-bottom: 10px; }
.y-info-inner { display: flex; gap: 20px; } 
.y-info-box { height: fit-content; padding: 1.25rem; display: flex; flex-direction: column; border: 1px solid #e0e0e0; border-radius: 5px; box-shadow: 0 1px 1px rgba(0, 0, 0, .05); width: 33.33%; background: #fff; gap: 10px; justify-content: center; text-align: center; } 
.y-info-box .tit { font-size: 21px; font-weight:600; }
.y-info-box .txt { margin-top: 15px; } 
.y-info { margin-top: 40px; } 
.y-info .table-wrapper { display: flex; gap: 20px; } 
.y-info .table-data { background: #fff; border: 1px solid #e0e0e0; box-shadow: 0 1px 1px rgba(0, 0, 0, .05); transition: .2s; border-radius: 5px; flex: 0 0 auto; display: flex; padding: 30px 20px; flex-direction: column; width: 25%; gap: 10px; flex: 1 1 auto; } 
.y-info .table-data:hover { border: 1px solid #007bff; box-shadow: 0 10px 30px #e4edf0; } 
.y-info .table-data .data-header { font-size: 20px; font-weight: 700; } 
.y-info .table-data .item-wrapper { display: flex; flex-direction: column;}
.y-info .table-data .item-wrapper .item-title { border-bottom: 1px solid #e3e3e3; padding: 10px 0; } 

.modal-wrap { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, .6); z-index: 20; } 
.modal-wrap .modal-contents { position: fixed; background-color: #fff; width: 350px; height: fit-content; top: 50%; left: 50%; animation: fadein .3s ease-in forwards; color: #111; border-radius: 5px; } 
.modal-wrap .modal-contents__header { display: flex; padding: 10px; justify-content: center; align-items: center; position: relative; font-size: 18px; font-weight: 600; padding-bottom: 8px; border-bottom: 1px solid rgba(209, 209, 209, 0.6); } 
.modal-wrap .modal-contents__main { overflow-y: auto; height: fit-content; padding: 15px 20px; } 
.modal-wrap .modal-contents__footer { height: fit-content; padding: 15px 20px; text-align: right; display: flex; justify-content: flex-end; } 
.modal-wrap .modal-contents__footer .btn-close { background-color: rgba(0, 0, 0, 0); color: #343a40; border: 1px solid #343a40; } 
.modal-wrap .modal-contents__footer .btn-close:hover,.modal-wrap .modal-contents__footer .btn-close:focus { background-color: rgba(0, 0, 0, 0); color: #343a40; border: 1px solid #343a40; } 

.payment-plan-wrap { display: flex; gap: 20px; height: fit-content; padding: .625rem 0; display: flex; flex-direction: column; border-top: 2px solid #818181; background: #fff; gap: 10px; justify-content: center; text-align: center; } 
.payment-plan-wrap .p-plan { border-bottom: 1px solid #e3e3e3; padding: 10px 0; display: flex; gap: 10px; } 
.payment-plan-wrap .p-plan .p-title { font-weight: 700; font-size: 16px; min-width: 180px; line-height: 150%; } 
.p-info-box { width: 100%; } 
.p-info-box * { text-align: left; font-size: 16px; } 
.p-info-point-txt { color: #bf1900; font-weight: 700; } 
.point-txt { color: #bf1900; } 
.p-info-box .form-group { display: flex; flex-direction: row; align-items: center; } 
.p-info-box .form-group label { min-width: 64px; padding-bottom: 0; } 
.p-info-box .form-group .form-control { max-width: 300px; } 
 .flex-cl { flex-direction: column; justify-content: flex-start; align-items: flex-start; } 
 .terms-box { border: 1px solid #e3e3e3; padding: 10px; min-height: 200px; } 
 .order-payment-wrap { display: flex; flex-direction: column; gap: 20px; } 
 .order-payment-wrap table { width: 100%; } 
 .table-st-1 { border-top: 2px solid #818181;; } 
 .table-st-1 thead th { background: #f8f8f8; }
 .table-st-1 th { padding: 0.75rem; }
 .table-st-1 tbody th { background: #f8f8f8; } 
 /* .order-payment-box+ .order-payment-box { border-top: 1px solid #e3e3e3; } */
.order-payment-box .order-head { padding: 20px 0px 0; margin-bottom: 10px; } 

.order-payment-box .order-head .order-title { font-size: 18px; font-weight: 500; margin-bottom: 5px; } 
.order-payment-box .order-cont { padding: 10px 0px; } 
.amount-wrap { display: flex; gap: 20px; flex-direction: column; } 
.amounmt-inner { display: flex; gap: 20px; border: 1px solid #e3e3e3; padding: 10px !important; } 
.amount-box { flex: 1; padding: 30px 40px; } 
.amount-box +.amount-box { border-left: 1px solid #e3e3e3; } 
.amount-box dl { display: flex; margin-bottom: 10px; } 
.amount-box dt { float: left; position: relative; width: 200px; -webkit-box-sizing: border-box; box-sizing: border-box; -webkit-box-flex: 1; -ms-flex: 1 1; flex: 1 1; font-weight: 600; } 
.amount-box dd { position: relative; margin-left: 200px; word-wrap: break-word; word-break: break-word; margin-left: auto; -ms-flex-negative: 0; flex-shrink: 0; } 
.amount-line { display: flex; align-items: center; gap: 4px; justify-content: flex-end; } 
.amount-cash { text-align: right; width: 120px; } 
.a-title { font-size: 16px; margin-bottom: 20px; font-weight: 700; } 
.ao-t { border-top: 1px solid #343a40; padding-top: 20px; } 
.ao-st { font-size: 13px; text-align: right; } 

.amounmt-total { display: flex; padding: 10px 0; } 
.amounmt-total { display: flex; gap: 40px; justify-content: center; align-items: center; width: 100%; } 
.amount-to-item { text-align: center; } 
.amount-to-item p { font-size: 24px; } 
.amount-to-item span { margin-bottom: 5px; display: block; color: #818181; } 
.amount-to-item .t-tx { color: #007bff; font-weight:700; } 

.pay-method { display: flex; gap: 20px; border: 1px solid #e3e3e3; padding: 20px; flex-direction: column; } 
.pay-method label { font-size: 16px; font-weight: 600; vertical-align: middle; } 
.pay-method label input[type=radio] { width: 16px;height:16px}
.method-txt { display: flex; gap: 10px; flex-direction: column;}

.charge-info { display: flex; align-items: center; } 
.charge-info p { line-height: 1.7; } 
.charge-info__btn { all: unset; display: block; background-color: #fff; border: 1px solid #ffd965; font-weight: 600; font-size: 12px; border-radius: 3px; padding: 3px 5px; } 
.charge-info__btn:hover { background-color: #ffd965; } 
.charge-info-wrap { display: flex; justify-content:space-between; } 
.charge-info-wrap .charge-info { flex: 1; } 

.btn-lg { font-size: 18px; } 
.btn-pay svg { margin-right: 6px; } 
.method-txt .t { font-weight: 600; } 
.warring-t { padding: 20px; background: #eaf5ff; font-size: 15px; color: #007bff; border: 1px solid #d6e8f8; } 
.justify-end { justify-content: flex-end; } 

.btn.btn-nomal { background-color: rgba(0, 0, 0, 0); color: #343a40; border: 1px solid #343a40; } 
.btn.btn-nomal:not(:disabled):not(.disabled):active, .btn.btn-nomal:not(:disabled):not(.disabled):hover { background-color: rgba(0, 0, 0, .1); border: 1px solid #343a40; } 

.mail-send-wrap { display: flex; align-items: center; gap: 4px; padding: 10px; background: #f8f8f8; border: 1px solid #dee2e6; } 
.mail-send-wrap > label { font-weight: 600; } 
.mail-send-wrap .mail-form { flex: 1; display: flex; align-items: center; gap: 4px; } 

/* Dropdown Button */
.dropbtn { font-weight: 700; font-size: 0.93rem; background-color: transparent; color: white; padding: 0.5rem 0.5rem 0.5rem 1.3rem; margin: 0 0.4rem; border: none; cursor: pointer; background-repeat: no-repeat; background-position: 0 50%; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.4' height='15.4' viewBox='0 0 15.4 15.4'%3E%3Cg id='Icon_feather-globe' data-name='Icon feather-globe' transform='translate(-1.3 -1.3)'%3E%3Cpath id='패스_505' data-name='패스 505' d='M16,9A7,7,0,1,1,9,2a7,7,0,0,1,7,7Z' transform='translate(0 0)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.4'/%3E%3Cpath id='패스_506' data-name='패스 506' d='M2,9H16M9,2a10.71,10.71,0,0,1,2.8,7A10.71,10.71,0,0,1,9,16,10.71,10.71,0,0,1,6.2,9,10.71,10.71,0,0,1,9,2Z' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.4'/%3E%3C/g%3E%3C/svg%3E"); } 
.dropdown { position: relative; display: inline-block; } 
.dropdown-content { display: none; position: absolute; background-color: #f1f1f1; min-width: 120px; box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); z-index: 1; } 
.dropdown-content a { padding: 10px 10px; text-decoration: none; display: block; } 
.dropdown-content a:hover { background-color: #ddd; } 
.show { display:block; } 

.manual-wrap { display: flex; gap: 40px; position: relative; } 
.manual-index-wrap { width: 250px; } 
.manual-index { position: sticky; top: 100px; background: #eaeff3; padding: 20px; border-radius: 10px; max-height: calc(100vh - 120px); overflow-y: auto; } 
.manual-index .tit { font-size: 18px; font-weight: 700; border-bottom: 1px solid #c5c5c5; padding-bottom: 10px; } 
.manual-index>li { margin-bottom: 20px; } 
.manual-index li a { font-size: 16px; padding: 3px 0; display: block; font-weight: 600; } 
.manual-index li ul li { padding-left: 16px; } 
.manual-index li ul li a { font-size: 15px; font-weight: 400; } 
.manual-contents-box { display: flex; gap: 20px; flex-direction: column; line-height: 150%; } 
.manual-contents-box>.manual-contents-box { gap: 10px; } 
.manual-contents-box h3 { font-size: 20px; font-weight: 500; } 
.manual-contents-box h4 { font-size: 18px; font-weight: 500; } 
.manual-info-box { display: flex; gap: 10px; flex-direction: column; } 
.manual-info-box img { width: 100%; } 
.manual-contents-wrap { width: 100%; flex: 1; display: flex; flex-direction: column; gap: 40px; } 
.manual-desc { margin-left:20px; } 
.manual-desc li { margin-left:20px; } 
.h-list>li { list-style-type:hangul; line-height: 150%; margin-bottom: 10px; font-weight: 500; } 
.d-list li { list-style: none; margin-left: 0; font-weight: 400; } 
.d-list li::before { content: '-'; display: inline-block; margin-right: 3px; } 
.n-list li { list-style-type:decimal; margin-left:20px; margin-bottom: 10px; line-height: 150%; } 
table td .n-list li { margin-bottom: 0px; } 
.manual-contents-box table { min-width: 100%; border-collapse: collapse; } 
.manual-contents-box table thead th { background-color: #f5f5f5; border-top: 1px solid #333; } 
.manual-contents-box table th, .manual-contents-box table td { border: 1px solid #e8e8e8; padding: 10px 10px; } 
.mgt10 { margin-top: 10px; } 

 @media screen and (max-width: 990px){
 .navbar .nav-menu__link { width: auto !important; } 
 .nav-menu .dropdown { text-align: left; padding: 2rem; width: 100%; display: table; } 
 }