.login {
  background-color: #fff;
  width: 350px;
  margin: 0 auto;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
  }

  &__append {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    a {
      all: unset;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__notification {
    padding: 0px 25px;
    p {
      display: block;
      margin: 40px 0px 10px 0px;
    }
    a {
      all: unset;
      cursor: pointer;
      width: 100%;
      height: auto;
    }
  }
}
