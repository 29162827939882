.layout {
  padding-top: 80px;
  min-height: 80vh;
  display: block;
  max-width: 1140px;
  margin: 0 auto;
}

@media screen and (max-width: 990px) {
  .layout {
    padding-top: 0;
    min-height: 80vh;
    max-width: 90%;
  }
}
