$modal-contents-padding: 20px;

.preview-wrapper__append {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: $modal-contents-padding;
  margin-top: $modal-contents-padding;
}

.detail-preview {
  width: 100%;
  justify-content: center;
  flex-direction: column;

  .label--bg-basic {
    border: 1px solid #eaeaea;
  }
  .label--bg-special {
    border: 1px solid #ffc107;
    background-color: #ffc107;
    color: #ffffff;
  }
  .label--bg-premium {
    border: 1px solid #7266ba;
    background-color: #7266ba;
    color: #ffffff;
  }
  .total-point {
    margin: 30px 0px 15px 0px;
    font-size: 20px;
    font-weight: bold;
  }
}
