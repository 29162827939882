* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
}

a {
  all: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

ol,
ul {
  list-style: none;
}

fieldset {
  all: unset;
}

.divider {
  width: 100%;
  padding: 0 24px;
  height: 1px;
  margin: 25px auto;
  background-color: #e5e5e5;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-table {
  display: table !important;
}

/* 페이지 공통양식 */
.page-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  min-width: 1140px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 40px;

  .page__header {
    padding-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
  }
  .page__title {
    font-size: 20px;
    font-weight: 500;
  }
  .page__subtitle {
    margin-top: 5px;
    font-size: 12px;
    display: block;
  }
}

/* 카드 공통양식 */
.card {
  height: fit-content;
  padding: 0.625rem 0.9375rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #e0e0e0;
  border-top: 3px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 5%);

  &__header {
    width: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: bold;
    border-bottom: 0;
    padding: 0.625rem 0.9375rem;
    background-color: #ececec;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem 5rem;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.6;
  }
}

.card + .card {
  margin-top: 20px;
}

/* 테이블-페이지네이션 공통양식 */
.table-pagination-container {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-top-width: 3px;
  border-color: #cfdbe2;

  .table-title {
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 0.25rem;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .table-container {
    padding: 15px;
    table {
      border-collapse: collapse;
      table-layout: auto;
      width: 100%;
    }
    th,
    td {
      text-align: center;
      padding: 12px;
      min-height: 50px;
    }
    th {
      background-color: #fff;
      border: none;
      padding: 14px 8px;
      color: #888;
      border-bottom: 1px solid #ced4da;
    }
    td {
      background-color: transparent;
    }
    tr {
      border-top: 1px solid #ced4da;
      &:nth-child(odd) {
        background-color: #ececec;
      }
      &:first-child {
        border-top: none;
      }
    }
  }

  .pagination-container {
    background-color: #ececec;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.125);

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      outline: none;
      width: fit-content;
      height: fit-content;
      padding-right: 10px;

      & li {
        border: 1px solid rgba(0, 0, 0, 0.125);
        outline: none;
        cursor: pointer;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.7);
        background-color: #fff;

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        a {
          text-decoration: none;
          display: block;
          width: 100%;
          height: 100%;
          padding: 5px 10px;
        }
      }

      & .active {
        background-color: #085ed6;
        border-color: #085ed6;
        color: #fff;
      }
    }
  }
}

/* 공통 버튼 */
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.5px 10px;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  border: 0px;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
  transition: background-color 0.2s;
  text-decoration-line: none;

  &.btn-l {
    font-size: 14px;
    padding: 12px 15px;
  }
  &.btn-xl {
    font-size: 16px;
    padding: 15px 18px;
  }
  &.btn-xxl {
    font-size: 18px;
    padding: 18px 22px;
  }
  &.btn-s {
    font-size: 14px;
    padding: 8px 10px;
  }
  &.btn-xs {
    padding: 3px;
    font-size: 0.8125rem;
  }

  &:active,
  &:hover {
    text-decoration-line: none;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):hover {
    background-color: #0069d9;
    border-color: #0069d9;
  }

  &.disabled {
    border: 1px solid #eaeaea;
    color: #6c757d;
    background-color: transparent;
    opacity: 0.65;
    pointer-events: none;
  }

  &.btn-outline {
    &__info {
      color: #17a2b8;
      background-color: transparent;
      background-image: none;
      border: 1px solid #17a2b8;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):hover {
        background-color: #17a2b8;
        border-color: #17a2b8;
        color: #ffffff;
      }
    }
    &__green {
      color: #37bc9b;
      background-color: transparent;
      background-image: none;
      border: 1px solid #37bc9b;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):hover {
        background-color: #37bc9b;
        border-color: #37bc9b;
        color: #ffffff;
      }
    }
  }
}
.btn + .btn {
  margin-left: 10px;
}

.dummy-btn {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  font-size: 0.875rem;

  &.bg-w {
    background-color: #ffffff;
    color: inherit;
    border: 1px solid #ced4da;
  }
}

.append {
  .btn,
  .dummy-btn {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.btn {
  &.bg-wert {
    background-color: #3aa7c6;
    border: 1px solid #3aa7c6;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      color: #fff;
      background-color: #0062cc;
      border-color: #0062cc;
    }
  }
  &.bg-kk {
    background-color: #23b7e5;
    transition: background-color 0.1s;
    border: 1px solid #23b7e5;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      color: #fff;
      background-color: #37aed3;
      border-color: #37aed3;
    }
  }
  &.bg-w {
    background-color: #ffffff;
    color: inherit;
    border: 1px solid #ced4da;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      background-color: #d7dddf;
      border-color: #d7dddf;
    }
  }
  &.bg-danger {
    background-color: #f05050;
    color: #fff;
    border: 1px solid #f05050;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      background-color: #c82333;
      border-color: #c82333;
    }
  }
  &.bg-grey {
    background-color: #6c757d;
    color: #fff;
    border: 1px solid #6c757d;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      background-color: #545b62;
      border-color: #545b62;
    }
  }
}
.btn + .btn {
  margin-left: 10px;
}

/* 공통 뱃지*/
.badge {
  display: inline-block;
  padding: 0.1875rem 0.4375rem;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.625rem;
}

/* badge */
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

// margin
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}

.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-90 {
  margin-left: 90px !important;
}

.mr-0 {
  margin-right: 0px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}

// padding
.pd-0 {
  padding: 0px !important;
}
.pd-5 {
  padding: 5px !important;
}
.pd-10 {
  padding: 10px !important;
}
.pd-15 {
  padding: 15px !important;
}
.pd-20 {
  padding: 20px !important;
}
.pd-25 {
  padding: 25px !important;
}
.pd-30 {
  padding: 30px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}

.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}

.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}

// line-height
.line-height-2 {
  line-height: 1.2;
}
.line-height-4 {
  line-height: 1.4;
}
.line-height-6 {
  line-height: 1.6;
}
.line-height-7 {
  line-height: 1.7;
}
.line-height-two {
  line-height: 2;
}
.line-height-three {
  line-height: 3;
}
.line-height-four {
  line-height: 4;
}

// font-size
.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.font-size-30 {
  font-size: 30px !important;
}

// text-decoration
.text-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.block {
  display: block;
}

/* width height */
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-one-three {
  width: 30% !important;
}

.w-two-three {
  width: 66% !important;
}

.w-one-four {
  width: 25% !important;
}

.w-one-five {
  width: 20% !important;
}

.w-custom-1 {
  width: calc(100% - 90px) !important;
}

.h-full {
  height: 100%;
}
.h-half {
  height: 50%;
}

/* border */

.border-warning {
  border-color: #ffc107 !important;
}

/* text */
.text-kk {
  color: #23b7e5 !important;
}
.text-primary {
  color: #5d9cec !important;
}
.text-danger {
  color: #f05050 !important;
}
.text-disabled {
  color: #dde6e9 !important;
}
.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}
.text-align-center {
  text-align: center;
}
.text-align-start {
  text-align: start !important;
}
.text-align-end {
  text-align: end !important;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-sm {
  font-size: 0.74375rem;
}
.text-md {
  font-size: 1.6625rem;
}
.text-lg {
  font-size: 2.625rem;
}

/* border */
.border-0 {
  border: 0px;
}
.border-top-0 {
  border-top: 0px !important;
}
.border-right {
  border-right: 1px solid #dee2e6;
}
.border-left {
  border-left: 1px solid #dee2e6;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6;
}
.border-dark {
  border-color: #343a40 !important;
}

/* display flex */
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-direction-row {
  flex-direction: row !important;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-flex-start {
  align-items: flex-start;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}

/* background-color */
.bg-primary {
  background-color: #5d9cec !important;
  color: #ffffff !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}

/* z-index*/

.z-index-10 {
  z-index: 10 !important;
}
.z-index-20 {
  z-index: 20 !important;
}
.z-index-30 {
  z-index: 30 !important;
}
.z-index-40 {
  z-index: 40 !important;
}
.z-index-50 {
  z-index: 50 !important;
}

/* 모달 애니메이션 */
@keyframes fadein {
  from {
    transform: translate(-50%, -200%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

/* 로딩 스피너 */

.loading {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 5px solid #778ca3;
  border-top: 5px solid #45aaf2;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
