
/* KoPub Dotum */
@font-face {
    font-family: 'KoPub Dotum Light';
    font-style: normal;
    src: url(/font/KoPubDotumLight.ttf) format('truetype');
    src: url(/font/kopub_dotum_light-webfont.woff) format('woff');
}
@font-face {
    font-family: 'KoPub Dotum Medium';
    font-style: normal;
    src: url(/font/KoPubDotumMedium.ttf) format('truetype');
    src: url(/font/kopub_dotum_medium-webfont.woff) format('woff');
}
@font-face {
    font-family: 'KoPub Dotum Bold';
    font-style: normal;
    src: url(/font/KoPubDotumBold.ttf) format('truetype');
    src: url(/font/kopub_dotum_bold-webfont.woff) format('woff');
}

/**
 * Noto Sans KR
 */
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

/**
 * NanumSquareRound
 */
@font-face {
    font-family: NanumSquareRound;
    src: url(/font/NanumSquareRoundR.eot); /* IE 호환성 보기 */
    src: url(/font/NanumSquareRoundR.eot#iefix) format('embedded-opentype'), /* IE 6 ~ 8 */
    url(/font/NanumSquareRoundR.woff) format('woff'), /* 모던 브라우저 */
    url(/font/NanumSquareRoundR.ttf) format('truetype');
}

@font-face {
    font-family: NanumSquareRound-b;
    src: url(/font/NanumSquareRoundBold.woff) format('woff'), /* 모던 브라우저 */
    url(/font/NanumSquareRoundBold.ttf) format('truetype');
}

@font-face {
    font-family: NanumSquareRound-b-ex;
    src: url(/font/NanumSquareRoundExtraBold.woff) format('woff'), /* 모던 브라우저 */
    url(/font/NanumSquareRoundExtraBold.ttf) format('truetype');
}

@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

.round-text {
    font-family: NanumSquareRound;
    font-weight: normal;
}
.round-text-bold {
    font-family: NanumSquareRound-b;
    font-weight: bold;
}
.round-text-bold-ex {
    font-family: NanumSquareRound-b-ex;
    font-weight: bolder;
}
