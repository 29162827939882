.footer {
  padding: 30px 200px;
  width: 100%;
  height: auto;
  background-color: #4f5f79;
  color: white;
  font-size: 14px;

  &-contents {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
    padding: 20px;

    &__company {
      margin-top: 10px;
      width: 100%;
      color: #aaa;
      line-height: 1.7;

      .name :before {
        content: "";
        margin: 0 8px;
        display: inline-block;
        width: 3px;
        height: 10px;
        background-color: #bdc3c7;
      }
      .addr :before {
        content: "";
        margin: 0 8px;
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: #bdc3c7;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .footer {
    min-width: 750px;
    overflow: hidden;
    &-contents {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__company {
        margin-top: 12px;
        text-align: center;
      }
    }
  }
}
