//* date-picker 스타일링 */

$input-height: 37px;
$input-line-height: 1.5;
$input-border-radius: 3px;
$input-border-size: 1px;

.react-datepicker-wrapper {
  width: 80% !important;
}

.date-picker__control {
  display: block;
  width: 100%;
  height: auto;
  min-height: 37px;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: $input-border-size solid #ced4da;
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #fff;
    border-color: #66afe9;
  }
  &:focus-visible {
    outline: none;
  }
  &.disabled {
    box-shadow: unset !important;
    background-color: #e9ecef !important;
  }
  &.is-valid {
    border-color: #28a745;
  }
  &.is-invalid {
    border-color: #dc3545;
  }
  &::placeholder {
    color: #b7bac9;
    opacity: 1;
  }
}

.date-picker__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 37px;
  background-color: #e9ecef;
  border: $input-border-size solid #ced4da;
  border-left: none;
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
}

.date-picker-container {
  width: 420px;
  display: flex;

  .date-picker-chaining {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: auto;
  }
}

.date-picker-between {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
