.charge-mng__search-form {
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    height: fit-content;
  }
  &-bottom {
    position: relative;
    display: flex;
    height: 37px;
    input {
      width: 200px;
      &:first-child {
        width: 150px;
      }
    }

    button:last-child {
      position: absolute;
      right: 0;
    }
  }
}
