.board-news-dtl__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  div:first-child {
    font-size: 18px;
    font-weight: 600;
  }
  div:last-child {
    margin-top: 30px;
    strong {
      margin-right: 10px;
    }
  }
}

.board-news-dtl__header__sub {
  display: contents;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 300;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  strong {
    margin-right: 10px;
  }
}

.board-news-dtl__main {
  word-break: break-all;
  padding: 0 20px;
  line-height: 2;
  min-height: 30vh;
}

.board-news-dtl__main__sub {
  word-break: break-all;
  padding: 0 20px;
  line-height: 1;
  min-height: 5vh;
}

.board-news-dtl__comment {
  padding: 0 20px;
  line-height: 3;
  min-height: 20vh;
}

.board-news-dtl__footer {
  word-break: break-all;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  a {
    width: fit-content;
  }
  a:hover {
    text-decoration: none;
  }
}
.list-btn {
  all: unset;
  margin: 7px auto;
  background-color: #17a2b8;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  span {
    margin-left: 5px;
  }
  &:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
}