$modal-contents-padding: 20px;
.plan-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  justify-content: center;
  align-items: center;
  &-contents {
    background-color: #fff;
    color: #111;
    border-radius: 5px;
    &__header {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 600;
      padding: 0.625rem 0.9375rem;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &__close-btn {
      position: absolute;
      font-size: 20px;
      top: 5px;
      right: 20px;
      cursor: pointer;
    }
    &__main {
      overflow-y: auto;
      height: 100%;
      padding: $modal-contents-padding;
    }
    &__footer {
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      padding: $modal-contents-padding;
    }
  }
}
