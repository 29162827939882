.trans-req-mng__search-form {
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    height: fit-content;
  }
  &-bottom {
    display: flex;
    height: 37px;
    input {
      width: 200px;
      &:first-child {
        width: 150px;
      }
    }
  }
}

.trans-req-mng__table-container {
  table {
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.far.fa-file-pdf {
  font-size: 30px;
  color: #f05050;
}
.far.fa-file-word {
  font-size: 30px;
  color: #5d9cec;
}
