.faq-contents-list__search-form {
  display: flex;
  position: relative;
  .input-chaining {
    width: 50%;
  }
  button:last-child {
    position: absolute;
    right: 0;
    height: 37px;
  }
  a {
    text-decoration: none;
  }
}
