$navbar-color: #ffab02;

.navbar {
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  z-index: 10;
  background-color: $navbar-color;

  &__logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    img {
      max-width: 260px;
    }
  }
  &__hamburger-btn {
    display: none;
  }

  .fa-bars,
  .fa-times {
    color: #fff;
  }

  .nav-menu {
    display: flex;
    gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: 2rem;
    font-weight: 700;
    font-size: 0.93rem;
    &__link {
      display: flex;
      color: white;
      text-decoration: none;
      padding: 0.5rem;
      margin: 0 0.4rem;
      &:hover {
        color: #30336b;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .dropdown-menu {
    width: 170px;
    box-shadow: rgb(0 0 0 / 18%) 0px 6px 12px;
    border-radius: 10px;
    position: absolute;
    top: 55px;
    list-style: none;
    text-align: start;

    li {
      background-color: #fff;
      cursor: pointer;
      padding-left: 10px;
      &:hover {
        background-color: $navbar-color;
      }
    }

    &__link {
      display: block;
      height: 100%;
      width: 100%;
      text-decoration: none;
      color: #111;
      padding: 16px;
      span {
        margin-left: 10px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .navbar {
    position: relative;
    display: flex;
    align-items: center;

    &__hamburger-btn {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      li {
        position: relative;
      }
      &.active {
        background-color: $navbar-color;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
      &__link {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        &:hover {
          color: #30336b;
        }
      }
    }
    .dropdown-menu {
      position: absolute;
      left: 50%;
      z-index: 100;
    }
  }
}
